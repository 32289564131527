import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PageSectionHeaders from 'components/PageSectionHeaders/Loadable';
import { media } from '../../theme/styled-theme';
import patternUrl from '../../assets/pattern-calender.png';

/* -------------------------------------------------------------------------- */
/*                              styled components                             */
/* -------------------------------------------------------------------------- */
const Root = styled.div`
  position: relative;
  font-family: ${props => props.theme.fontSourceSansPro};
  width: 100%;
  display: flex;
  align-items: center;
  border-radius: 4px;
  flex-direction: column;
  padding: 0 1rem 3rem;
  overflow: hidden;
`;

const BackgroundPicture = styled.div`
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ccecff;
  background: url("${props => props.pattern}") top left/200px 200px;
`;

const ContentWrap = styled.div`
  border-radius: 2px;
  box-shadow: 0 16px 40px 0 rgba(6, 28, 63, 0.2);
  background-color: #ffffff;
  padding: 1.5rem;
  position: relative;
  min-width: 360px;

  ${media.forPhoneOnly`
    width: 100%;
    min-width: 100%;
    padding: 1rem;
  `}
`;

/* -------------------------------------------------------------------------- */
/*                               React Component                              */
/* -------------------------------------------------------------------------- */
const HomepageBottomSearch = ({ children, title, subtitle }) => {
  const { t } = useTranslation('new-version');
  const _title = title || t('HOME.LAST_CALL_TO_ACTION.TITLE');
  const _subtitle = subtitle || t('HOME.LAST_CALL_TO_ACTION.SUBTITLE')
  return (
    <Root>
      <BackgroundPicture pattern={patternUrl} />
      <PageSectionHeaders
        title={_title}
        subtitle={_subtitle}
        center
      />
      <ContentWrap>
        { children }
      </ContentWrap>
    </Root>
  );
};

HomepageBottomSearch.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  title: PropTypes.string,
  subtitle: PropTypes.string,
};

HomepageBottomSearch.defaultProps = {
  children: null,
  title: '',
  subtitle: '',
};

export default HomepageBottomSearch;
